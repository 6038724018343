import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { NotificationComponent } from './notification.component';



const routes: Routes = [
  {
    path: '',
     component: NotificationComponent,
    data: {
      title: 'orderlist'
    },    
    canActivate: [AuthGuard] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationRoutingModule { }