import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    
    path: '/dashboard/dashboard1', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
      
  },
  
{
  path: '/search', title: 'Search', icon: 'ft-corner-down-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
},
  {
    path: '/orderlist', title: 'Pedidos', icon: 'ft-corner-down-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
},
{
    path: '/urgentorderslist', title: 'Pedidos Urgentes', icon: 'ft-repeat', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
},
{
    path : '/users', title: 'Usuarios', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
},
{
    path : '/balance', title: 'Balance', icon: 'ft-percent', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
},
{
    path : '/notifications', title: 'Notificaciones', icon: 'ft-upload-cloud', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
},
 
 ];
