import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../shared/auth/auth-guard.service';
import { UrgentordersComponent } from './urgentorders.component';



const routes: Routes = [
  {
    path: '',
     component: UrgentordersComponent,
    data: {
      title: 'urgentorders'
    },    
    canActivate: [AuthGuard] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UrgentordersRoutingModule { }