import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { OrderdetailsComponent } from '../orderdetails/orderdetails.component';

import { MatchHeightModule } from '../../shared/directives/match-height.directive';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UrgentordersComponent } from './urgentorders.component';
import { UrgentordersRoutingModule } from './urgentorder-routing.module';



@NgModule({
    imports: [
        CommonModule,
        UrgentordersRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule
    ],
    exports: [],
    declarations: [UrgentordersComponent],
    providers: [],
})
export class UrgentOrderModule { }