
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {  Orderdetailsvm } from '../model/orderdetails';
import { AuthService } from '../auth/auth.service';
import { Orderdetailsadd } from '../model/orderdetailsadd';
import { UrgentOrder } from '../model/urgentorder';

@Injectable()
export class OrderService {



  
  constructor(private authenticationSerice : AuthService, private http : HttpClient) {

  }


  getAllUrgentOrders ()
  {
    // add authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('currentUser'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.get(environment.endPointUri +'/api/cart/geturgentitems', httpOptions );
  }

  // getAllOrders ()
  // {
  //   // add authorization header with jwt token
  //   let user = JSON.parse(localStorage.getItem('currentUser'))

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type':  'application/json',
  //       'Authorization':'Bearer ' + user.token
  //     })}

  //     return  this.http.get(environment.endPointUri +'/api/orderpaginationapi?pageNumber1&pageSize=5', httpOptions );


  // }

  getAllOrderswithpage (page)
  {
    console.log("this is page")
    console.log(page)
    // add authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('currentUser'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.get(environment.endPointUri +'/api/orderpaginationapi?pageNumber='+(page.pageNumber +1)+'&pageSize=10', httpOptions );


  }


  getAllOrdersn ()
  {
    // add authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('currentUser'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.get(environment.endPointUri +'/api/cart/getallorders', httpOptions );


  }
 
  getOrderdetails (ordernumber)
  {
    // add authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('currentUser'))
    let params = new HttpParams().set('ordernumber', ordernumber);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token 
      }),params : params}

    // get users from api
    return this.http.get(environment.endPointUri + '/api/cart/getorderdetails', httpOptions)

  }
 
  postOrderdetails ( orderdetails : Orderdetailsvm[] ) 
  {
    //need to implement in backend
    
    // add authorization header with jwt token

    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    console.log("these are order details")
    console.log(orderdetails)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

    let body = orderdetails
          
    return  this.http.post(environment.endPointUri +'/api/cart/changeprice', orderdetails, httpOptions );


  }

  //new
  editOrderDetails( orderdetails : Orderdetailsvm)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

    let body = orderdetails
          
    return  this.http.post(environment.endPointUri +'/api/cart/editorderdetails', orderdetails, httpOptions );
  }
  addOrderDetails(orderdetails : Orderdetailsadd)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}
   
    return  this.http.post(environment.endPointUri +'/api/cart/addorderdetails', orderdetails, httpOptions );
  }
  deleteOrderDetails(orderdetails : Orderdetailsvm)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

   
          
    return  this.http.post(environment.endPointUri +'/api/cart/deleteorderdetails', orderdetails, httpOptions );
  }

  orderisPrinted(id)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

   
    return  this.http.get(environment.endPointUri +'/api/cart/orderisprinted?id='+id, httpOptions );
  }

  addItems(object)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

   
    return  this.http.post(environment.endPointUri +'/api/cart/addorderitems',object, httpOptions );
  }

  addorderlistItems(orderitems)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

    return  this.http.post(environment.endPointUri +'/api/cart/orderproductlist',orderitems, httpOptions );
  }

  //orderproductlistbalance
  addorderlistItemsBalance(orderitems)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

    return  this.http.post(environment.endPointUri +'/api/cart/orderproductlistbalance',orderitems, httpOptions );
  }

  urgentitemsprintstatus(urgentorderlist : UrgentOrder)
  {
 
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

   
    return  this.http.post(environment.endPointUri +'/api/cart/urgentitemsprintstatus',urgentorderlist, httpOptions );
  }

  search(shopName)
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

   
    return  this.http.get(environment.endPointUri +'/api/cart/searchorder?shopname='+shopName, httpOptions );
  }
}