<div class="container-fluid">
   
  <div class="row">
      <div class="col-lg-12 col-md-12">
          <div class="card">
              <div class="content" id="print-section">
                      <form [formGroup]="orderForm" novalidate>
                      <div class="row">
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label>Nombre de Producto</label>
                                  <input type="text" class="form-control border-input"  placeholder="Nombre de producto" formControlName="productName" >
                              </div>
                          </div>
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label>quantity</label>
                                  <input type="text" class="form-control border-input"  placeholder="quantity" formControlName="quantity" >
                              </div>
                          </div>
                          <div class="col-md-4">
                                  <div class="form-group">
                                      <label>price</label>
                                      <input type="text" class="form-control border-input" placeholder="price" formControlName="price">
                                  </div>
                              </div>
                          </div>
                      <div class="row">
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label>color</label>
                                  <input type="text" class="form-control border-input" placeholder="color" formControlName="color">
                              </div>
                          </div>
                      </div>  
                      <div class="text-center">
                          <button type="submit" [disabled]="!orderForm.valid" (click) = "onSubmitPage()"  class="btn btn-dark">Editar y Actualizar</button>
                         
                        </div>
                      <div class="clearfix"></div>
                  </form>
                  <!-- <p>Form value: {{ registerForm.value | json }}</p> -->
              </div>
          </div>
      </div>
  </div>
</div>
