import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/shared/user/user.service';
import { Router } from '@angular/router';
import { BalanceService } from 'app/balancedeusario/balance.service';
import { BehaviorSubject } from 'rxjs';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FbUser } from 'app/models/user';

@Component({
  selector: 'app-userbalancelist',
  templateUrl: './userbalancelist.component.html',
  styleUrls: ['./userbalancelist.component.scss']
})
export class UserbalancelistComponent implements OnInit {

  constructor(private userservice : UserService,private router: Router,private balanceserivce : BalanceService) { }
  
  rout =  new BehaviorSubject<string>("");
  rows 
  orignalrows
  temp = []
  columns = [
    { prop: 'email' },
    { prop: 'name' },
    { prop: 'username' },
    { prop: 'action' }
];

@ViewChild(DatatableComponent) table: DatatableComponent;
  ngOnInit() {
    console.log(this.router.url); 
  
    this.rout.next(this.router.url)
    
    // this.userservice.getUsers().pipe(
    //   switchMap( (user : any ) => {   
    //     return user
    //   })).pipe(
    //     map( (user : FbUser) =>{
    //      return this.balanceserivce.getActaveBalance(user.userId).pipe(
    //        map( (x: BalanceVm ) =>{
    //          if(user.balance)
    //          {
    //           user.balance = x.totalBalance
    //          }
    //          else{
    //            user.balance = 0
    //          }
    //         return user
    //        })
    //      )
    //     })
    //   )
    // .subscribe(x=>{
    //   x.subscribe(x =>{
    //     this.rows = x
    //   })
    // })
   this.userservice.getUsers().subscribe( (users : Array<FbUser>) =>{
     console.log(users)
    this.rows = users
    this.orignalrows = users
   })
  }

  onUserEvent ( e ) {
       this.router.navigate(['user/'+e['userId']]);
} 
  updateFilter(event) {
    debugger
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.orignalrows.filter(function (d) {
        if(d.name == null)
            d.name = "Empty"
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}

blockAgents(value){
  console.log(value)
  this.router.navigate(['balance/'+value['userId']]);
}
}
