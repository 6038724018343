export namespace Config{
    
  export const settings = {timestampsInSnapshots: true};
     
  export const firebaseConfig = {
    apiKey: "AIzaSyC56uUufBGhLVjgDN4qhwijuLTaNaWuXsI",
    authDomain: "siochat-cbf92.firebaseapp.com",
    databaseURL: "https://siochat-cbf92.firebaseio.com",
    projectId: "siochat-cbf92",
    storageBucket: "siochat-cbf92.appspot.com",
    messagingSenderId: "600641557841"
  };

  export const messagingkey = {  serverkey : "AAAAi9kG1VE:APA91bFt6L-Owau904V5F9B-UP4Z3yobO3EFdyycTgJ2ogI0YgeldcrH7XcNKQ0rhugz7lBTKYNyk6qaZxK8YVPFAr47nktL-kwadApskoTD5CcI2Wp5kaIE7JQmgxusfTjui15ea994"}
}