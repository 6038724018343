
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="card">
                <div class="content" id="print-section">
                        <form [formGroup]="orderForm" novalidate>
                        <div class="row">
                            <div class="col-md-6">
                                    <input type="text" class="form-control border-input"  placeholder="Nombre de producto" formControlName="productName" >
                                
                            </div>
                            <div class="col-md-2">
                                    <input type="text" class="form-control border-input"  placeholder="quantity" formControlName="quantity" >
                                
                            </div>
                            <div class="col-md-2">
                                        <input type="text" class="form-control border-input" placeholder="price" formControlName="price">
                                    
                                </div>
                                <div class="col-md-2">
                                        <input type="text" class="form-control border-input" placeholder="color" formControlName="color">
                                    
                                </div>
                            </div>
                         
                       
                    </form>
                  
                </div>
            </div>
        </div>
    </div>

