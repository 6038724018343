<!-- Filter Datatable Options Starts -->
<section id="filter" class="mb-3">
  <div class="row">
      <div class="col-12">
          <button (click)="printList()">Print</button>
      </div>
  </div>
  <div class="row" id="print">
      <div class="col-sm-12" >
          <ngx-datatable
          style="width: 90%"
          class='bootstrap'
          [rows]="rows"
          [columns]="columns"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="10"
          [selected]="selected"
          [selectionType]="'checkbox'"
          [selectAllRowsOnPage]="false"
          (select)='onSelect($event)'>
</ngx-datatable>
      </div>
  </div>
</section>
