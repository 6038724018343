<!-- Filter Datatable Options Starts -->
<section id="filter" class="mb-3">
  <div class="row">
      <div class="col-12">
                    <div class="position-relative has-icon-right">
                        <input type="text" class="form-control round" placeholder="Search" (keyup)='updateFilter($event)'>
                    </div>
      </div>
  </div>
  <div class="row">
      <div class="col-sm-12">
            
         
          <ngx-datatable   class="bootstrap core-bootstrap"  [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
              [rowHeight]="'auto'" [rowClass]="getRowClass" [limit]="18" [rows]='rows' (activate)='onUserEvent($event)'  >
              <ngx-datatable-column name="orderNumber"></ngx-datatable-column>
              <ngx-datatable-column name="shopName"></ngx-datatable-column>
              <ngx-datatable-column name="orderDate">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date: 'dd/MM/yyyy'}}
                </ng-template>
              </ngx-datatable-column>
             
              <ngx-datatable-column name="Total"  ></ngx-datatable-column>
              <ngx-datatable-column  name="isPrinted">
                    <ng-template ngx-datatable-header-template>
                            <span>Estado</span>
                    </ng-template> 
                  <ng-template let-row="row" ngx-datatable-cell-template>          
  
                      <div class="printed" *ngIf="row.isPrinted;else other_content">
                          IMPRIMIDO
                      </div>
                      
                      <ng-template #other_content >
                        <p class="noprinted">NO IMPRIMIDO</p></ng-template>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column  name="isPrinted">
                    <ng-template ngx-datatable-header-template>
                            <span>Estado</span>
                    </ng-template> 
                  
                </ngx-datatable-column>
                <ng-template let-row="row" ngx-datatable-cell-template>          
  
                    <div style="background: red;" *ngIf="row.totalBalance < 0 ; else other_content">
                        {{row.totalBalance}}
                    </div>
                    
                    <ng-template #other_content >
                      <p style="background:green"> {{row.totalBalance}}</p></ng-template>
                  </ng-template>
            </ngx-datatable>
      </div>
  </div>
</section>
