<!-- Filter Datatable Options Starts -->
<section id="filter" class="mb-3">
  <div class="row">
    <div class="container-fluid">
      <section class="row">
          <div class="col-md-8">
              <h1>Pedidos</h1>
          </div>
          <div class="col-md-4">
              <div class="btn-group float-right mt-2" role="group">
                <button class="btn" (click)="exportAsXLSX()"><i class="fa fa-file-excel-o"></i>Exportar</button>
              </div>
          </div>
      </section>
  </div>
  </div>
  <div class="row">
      <div class="col-sm-12">
           <ngx-datatable #table class='bootstrap'
            [columns]="columns" 
            [columnMode]="'force'" 
            [headerHeight]="50" 
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [rows]='rows' 
            
          [externalPaging]="true"
          [count]="page.totalElements"
          [offset]="page.pageNumber"
          [limit]="page.size"
          (page)='setPage($event)'
            (activate)='onUserEvent($event)'  >
              <ngx-datatable-column name="shopName"></ngx-datatable-column>
              <ngx-datatable-column name="orderDate">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date: 'dd/MM/yyyy'}}
                </ng-template>
              </ngx-datatable-column>
             
              <ngx-datatable-column name="Total"  ></ngx-datatable-column>
              <ngx-datatable-column  name="isPrinted">
                  <ng-template ngx-datatable-header-template>
                      <span>Estado</span>
              </ng-template> 
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="badge badge-pill"
                  [ngClass]="{'bg-light-success': row.isPrinted ,'bg-light-danger':!row.isPrinted}">
                  {{  row.isPrinted ? "IMPRIMIDO" : "NO IMPRIMIDO" }}
                </div>
              </ng-template>
                  <!-- <ng-template let-row="row" ngx-datatable-cell-template>          
  
                      <div class="printed" *ngIf="row.isPrinted;else other_content">
                          IMPRIMIDO
                      </div>
                      
                      <ng-template #other_content >
                        <p class="noprinted">NO IMPRIMIDO</p></ng-template>
                    </ng-template> -->
                </ngx-datatable-column>
                <ngx-datatable-column  name="isPrinted">
                  <ng-template ngx-datatable-header-template>
                      <span>Balance</span>
              </ng-template> 
                  <ng-template let-row="row" ngx-datatable-cell-template>          
  
                      <div style="background: red;" *ngIf="row.totalBalance < 0 || row.totalBalance > 0">
                          {{row.totalBalance}}
                      </div>
                      
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
                   
          </div>
  </div>
</section>
