import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate() {
    if (localStorage.getItem('currentUser')) {
        // logged in so return true
        console.log(localStorage.getItem('currentUser'))
        console.log("your token" + " >>> " + localStorage.getItem('currentUser'))
        return true;
    }

    // not logged in so redirect to login page
  
    //this.router.navigate(['/login']);
    return false;
}
}
