
import { Component, ViewChild } from '@angular/core';
import { OrderService } from '../../shared/order/order.service';
import { Order } from '../../shared/model/order';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';

declare var require: any;
const data: any = []

@Component({
  selector: 'allorders',
  templateUrl: './allorders.component.html',
  styleUrls: ['./allorders.component.scss']
})
export class AllOrdersComponent {

  rows = [];

    temp = [];

    // Table Column Titles
    columns = [
        { prop: 'orderNumber' },
        { prop: 'orderDate' },
        { prop: 'isPrinted' },
        {prop : 'totalBalance'},
        { prop: 'Total' }
    ];
    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(private orderService : OrderService,private router: Router) {
        
        this.temp = [...data];
       
        this.orderService.getAllOrdersn().subscribe( (order : Order[] ) => {
        this.rows = order
            console.log(order)
        })
        

    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        // filter our data
        const temp = this.rows.filter(function (d) {
            if(d.shopName == null)
                d.shopName = "Empty"
            return d.shopName.toLowerCase().indexOf(val) !== -1 || !val;
        });

        // update the rows
        this.rows = temp;
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }
    
    onUserEvent ( e ) {
        if ( e.type == "click" ) {

            this.router.navigate(['orderdetails/'+e.row['orderNumber']]);
        
        }
    } 

    getRowClass(row) {
        return {
          'is-printed': (row.isPrinted % 10) === 0
        };
      }
      getCellClass({ row, column, value }): any {
        return {
          'is-printed': value === 'true'
        };
      }

}
