 <div class="container">
  
     <form (ngSubmit)="onSubmit()" >
   
      <div id="print-section">
          <hr>
          <table style="width:100%">
            <tr>
              <th>Information del Cliente </th>
              <th>Forma de Envio </th> 
            </tr>
            <tr>
              <td><div>
                <div>
                  <strong>{{orderdetails.shopName}}</strong><br>
                  <strong>{{orderdetails.email}}</strong><br>
                  <strong>{{orderdetails.shopTel}}</strong>
                  </div>
                </div></td>
              <td>
                  <div style= "width:20px; height:20px; border: 1px solid #000; display: inline-block;"></div> <strong> &ensp;&ensp;MRW</strong>   <br>              
                  <div style= "width:20px; height:20px; border: 1px solid #000; display: inline-block;"></div> <strong> &ensp;&ensp;CORREO EXPRESS</strong><br>
                  <div style= "width:20px; height:20px; border: 1px solid #000; display: inline-block;"></div> <strong> &ensp;&ensp;RETORNO</strong><br>
                  <div style= "width:20px; height:20px; border: 1px solid #000; display: inline-block;"></div> <strong> &ensp;&ensp;URGENTE</strong><br>
              </td>
            </tr>
          </table>


<hr><br><br>
      <div class="page-invoice-table table-responsive">
        <table class="table table-hover text-right">
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th class="text-left">Description</th>
              <th class="text-right">Quantity</th>
              <th class="text-right">Color</th>
              <th class="text-right">Unit Price</th>
              <th class="text-right">Total Price</th>
              <th class="text-right no-print">Price</th>
              <th class="text-right no-print">Edit</th>
              <th class="text-right no-print">Delete</th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let order of orderdetails.orderdetails; let index = index; trackBy:trackByIndex;">
              <td class="text-center">#</td>
              <td class="text-left">{{order.product.productName}}</td>
              <td> 
                
                  <div class="input-group" >
                  <p style="color: royalblue; font-size: 18px; margin-right: 10px;"><b>{{ order.product.quantity }}</b></p>
                  <input  type="text" class="form-control no-print" [name]="'name' + index" [(ngModel)]="order.product.quantity"  />
                  <button  class="no-print" (keyup.enter)="updatequantity(order)" (click)="updatequantity(order)" >Actualizar</button>
               </div>
                
                
                
              </td>
              <td>{{order.product.color}}</td>
              <td>{{order.product.price}}</td>
              <td>{{(order.product.price * order.product.quantity) | number  }}</td>
              <td class="no-print"><form> <input class="form-control" [(ngModel)]='order.product.price' name="price" [value]="order.product.price"    (change)="calculatetotal()"></form></td>
              <td class="no-print"><button type="button" (click)="loadOrderdetailsToEdit(order.id,order.product)">Edit</button> </td>
              <td class="no-print"><button type="button" (click)="loadOrderdetailsToDelete(order.id,order.product)">Delete</button> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="striped-border"></p>
      <p class="striped-border"></p>
      <p class="striped-border"></p>
      <p class="striped-border"></p>
      <p class="striped-border"></p>
      <p class="striped-border"></p>
      <div class="text-right ">
        <div class="float-right right ">
          <h3 class="grand-invoice-amount">Grand Total: <strong><b>{{total | number}}</b></strong></h3>
        </div>
      </div>

   


    </div> 
    <hr>
      <div class="row" style="padding-top: 20px;">
      <div class="col-sm-6">
        <div>
        <button type="submit" class="btn btn-dark">
          <span>  Guardar y Actualizar Precio</span>
        </button>
        </div>
        <div class="col-sm-2">
          <label>Order Statious (No Implimentado) : </label>
          <select (change)="orderstatuious($event.target.value)">
              <option value="0">--Order Statious--</option>
              <option *ngFor="let statious of orderstatious" value={{statious}}>
                  {{statious}}
              </option>
          </select>
      </div>

      </div>
      <div class="col-sm-6">
        <div class="col-sm-1"> <button type="button" class="btn btn-dark" (click)="print()">
          <span><i class="fa fa-print"></i> Print</span>
        </button></div>
        <div class="col-sm-6" *ngIf="activebalance | async as activeb">
         <label _ngcontent-uxv-c25="" >Balance Total : <p style="font-size: 20px; color: red;">{{activeb.totalBalance}}</p> </label><br>
          <label _ngcontent-uxv-c25="" >Fecha De Inicio Balance: {{activeb.customerBalanceDateTime | date}}</label>
          <button type="button" class="btn btn-dark" (click)="aplicarBlance(activeb)">
            <span><i class="fa fa-eur"></i> Aplicar Balance</span>
          </button>
        </div>
       
      </div>
    </div>
   </form>
   
  <edit-order-details [id]='id'></edit-order-details> 

  
  <div class="row">
    <div class="col-sm-12">
     <div class="chatbody">
     <div class="panel panel-primary">
   <div class="panel-heading top-bar">
       <div class="col-md-12 col-xs-12">
           <h3 class="panel-title">
              <div class="btn-group btn-group-justified">
                <button class="btn btn-secondary btn-xs " (click)="addItemDy()"><i class="fa fa-plus"></i> Agregar</button>
             <button class="btn btn-secondary btn-xs" (click)="viewChildren()"><i class="fa fa-save"></i> Guardar</button>
             <button class="btn btn-secondary btn-xs" (click)="rem()"><i class="fa fa-trash"></i> Borrar</button>
              </div>
             
            </h3>
       </div>
   </div>
   <div class="panel-body msg_container_base">
      <template #alertContainer></template>
   </div>
   <hr>
</div>
</div>
</div>
</div>
</div> 



