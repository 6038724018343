<div class="row">
	<div class="col-12">
		<div class="content-header">Inputs</div>
	</div>
</div>
<!-- Basic Inputs start -->
<section id="basic-input">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Basic</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<div class="row">
							<div class="col-md-6">
								<fieldset class="form-group">
									<label for="basicInput">Basic</label>
									<input type="text" class="form-control" id="basicInput"  placeholder="Enter email">
								</fieldset>

								<fieldset class="form-group">
									<label for="helpInputTop">Input text with help</label>
									<small class="text-muted">eg.<i>someone@example.com</i></small>
									<input type="text" class="form-control" id="helpInputTop">
								</fieldset>

								<fieldset class="form-group">
									<label for="helperText">With Helper Text</label>
									<input type="text" id="helperText" class="form-control" placeholder="Name">
									<small class="text-muted">Find helper text here for given textbox.</small>
								</fieldset>
							</div>
							<div class="col-md-6">
								<fieldset class="form-group">
									<label for="disabledInput">Disabled Input</label>
									<input type="text" class="form-control" id="disabledInput" placeholder="Disabled Text" disabled>
								</fieldset>
								<fieldset class="form-group">
									<label for="readonlyInput">Readonly Input</label>
									<input type="text" class="form-control" id="readonlyInput" readonly="readonly" value="You can't update me :P">
								</fieldset>

								<fieldset class="form-group">
									<label>Static Text</label>
									<p class="form-control-static" id="staticInput">email@pixinvent.com</p>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Basic Inputs end -->

<!-- Input Style start -->
<section id="input-style">
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Styles</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
					<p>Use <code>.round</code> or <code>.sqaure</code> along with <code>.form-control</code> for round/square bordered input.</p>
                    <div class="row">
                        <div class="col-sm-6">
                            <fieldset class="form-group">
                                <label for="roundText">Rounded Input</label>
                                <input type="text" id="roundText" class="form-control round" placeholder="Rounded Input">
                            </fieldset>
                        </div>
                        <div class="col-sm-6">
							<fieldset class="form-group">
                                <label for="squareText">Square Input</label>
                                <input type="text" id="squareText" class="form-control square" placeholder="Square Input">
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</section>
<!-- Input Style end -->

<!-- Horizontal Input start -->
<section id="horizontal-input">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Horizontal Input</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<p>Add <code>.col-form-label</code> to your <code>&lt;label&gt;</code> so that they’re vertically centered with their associated form controls.</p>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group row align-items-center">
									<div class="col-lg-2 col-3">
										<label for="first-name" class="col-form-label">First Name</label>
									</div>
									<div class="col-lg-10 col-9">
										<input type="text" id="first-name" class="form-control" name="fname" placeholder="First Name">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group row align-items-center">
									<div class="col-lg-2 col-3">
										<label for="last-name" class="col-form-label">Last Name</label>
									</div>
									<div class="col-lg-10 col-9">
										<input type="text" id="last-name" class="form-control" name="fname" placeholder="Last Name">
									</div>
								</div>
						</div>
					</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Horizontal Input end -->

<!-- Basic File Browser start -->
<section id="input-file-browser">
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">File Input</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <fieldset class="form-group">
                                <label for="basicInputFile">Simple File Input</label>
                                <input type="file" class="form-control-file" id="basicInputFile">
                            </fieldset>
                        </div>
                        <div class="col-md-6 col-12">
                            <fieldset class="form-group">
                                <label for="inputGroupFile01">With Browse button</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputGroupFile01">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</section>
<!-- Basic File Browser end -->

<!-- Floating Label Inputs start -->
<!-- <section id="floating-label-input">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Floating Label Inputs</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<p>For Flating Label Inputs, need to use <code>.form-label-group</code> class & add attribute <code>disabled</code> for disabled Floating Label Input.</p>
						<div class="row">
							<div class="col-md-4 col-sm-6">
								<fieldset class="form-label-group">
									<input type="text" class="form-control" id="floating-label1" placeholder="Label-placeholder">
									<label for="floating-label1">Label-placeholder</label>
								</fieldset>
							</div>
							<div class="col-md-4 col-sm-6">
								<fieldset class="form-label-group form-group position-relative has-icon-left">
									<input type="text" class="form-control" id="iconLabelLeft" placeholder="Icon Left, Default Input">
									<div class="form-control-position">
										<i class="bx bx-user"></i>
									</div>
									<label for="iconLabelLeft">Icon Left, Default Input</label>
								</fieldset>
							</div>
							<div class="col-md-4 col-sm-6">
								<fieldset class="form-label-group">
									<input type="text" class="form-control" id="floating-label-disable" placeholder="Label-placeholder" disabled>
									<label for="floating-label-disable">Disabled-placeholder</label>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</section> -->
<!-- Floating Label Inputs end -->

<!-- Input with Icons start -->
<section id="input-with-icons">
	<div class="row match-height">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Input with Icons</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<p>Use <code>.position-relative</code> with <code>.form-group</code> and use <code>.has-icon-&#123;left/right&#125;</code> for icon on left/right side.</p>
						<div class="row">
							<div class="col-md-6">
								<label>Left Icon</label>
								<fieldset class="form-group position-relative has-icon-left">
									<input type="text" class="form-control" id="iconLeft1" placeholder="Icon Left, Default Input">
									<div class="form-control-position">
										<i class="ft-user"></i>
									</div>
								</fieldset>
							</div>
							<div class="col-md-6">
								<label>Right Icon</label>
								<fieldset class="form-group position-relative has-icon-right">
									<input type="text" class="form-control" id="iconLeft2" placeholder="Icon Right, Default Input">
									<div class="form-control-position">
										<i class="ft-file"></i>
									</div>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Input with Icons end -->

<!-- Input Sizing start -->
<section id="input-sizing">
	<div class="row match-height">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Sizes</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<p>Use <code>.form-control-&#123;lg/sm&#125;</code> for large/small input box.</p>
						<div class="row">
							<div class="col-md-4">
								<fieldset class="form-group">
									<label for="large-input">Large</label>
									<input class="form-control form-control-lg" type="text" id="large-input" placeholder="Large Input">
								</fieldset>
							</div>
							<div class="col-md-4">
								<fieldset class="form-group">
									<label for="default-input">Default</label>
									<input class="form-control" type="text" id="default-input" placeholder="Default Input">
								</fieldset>
							</div>
							<div class="col-md-4">
								<fieldset class="form-group">
									<label for="small-input">Small</label>
									<input class="form-control form-control-sm" type="text" id="small-input" placeholder="Small Input">
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Input Sizing end -->

<!-- validations start -->
<section id="input-validation">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Validation States</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<p>You can indicate invalid and valid form fields with <code>.is-invalid</code> and <code>.is-valid</code>. Note that <code>.invalid-feedback</code> is also supported with these classes.</p>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group m-0">
									<label for="valid-state">Valid State</label>
									<input type="text" class="form-control is-valid" id="valid-state" placeholder="Valid" value="Valid" required>
									<div class="valid-feedback">
										<i class="ft-circle"></i>
										<span>This is valid state.</span>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group m-0">
									<label for="invalid-state">Invalid State</label>
									<input type="text" class="form-control is-invalid" id="invalid-state" placeholder="Invalid" value="Invalid" required>
									<div class="invalid-feedback">
										<i class="ft-circle"></i>
										<span>This is invalid state.</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- validations end -->

<!-- Tooltip validations start -->
<section id="tooltip-validation">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Validation States with Tooltips (Template Driven Form)</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<p>Use <code>form.needs-validation</code> and swap <code>.&#123;valid/invalid&#125;-feedback</code> with <code>.&#123;valid/invalid&#125;-tooltip</code> to display validation feedback in a styled tooltip.</p>
						<form class="needs-validation" #userForm="ngForm" (ngSubmit)="userForm.form.valid && onSubmit(userForm)">
							<div class="form-row">
								<div class="col-md-4 mb-4 form-group">
									<label for="validationTooltip01">First name</label>
									<input type="text" name="fname" class="form-control" id="validationTooltip01" placeholder="First name" [(ngModel)]="model.fname" #fname="ngModel"
                  [ngClass]="{ 'is-invalid': userForm.submitted && fname.invalid, 'is-valid': userForm.submitted &&  !fname.invalid }" required>
									<div *ngIf="userForm.submitted && !fname.errors?.required" class="valid-tooltip">Looks good!</div>
								</div>
								<div class="col-md-4 mb-4 form-group">
									<label for="validationTooltip02">Last name</label>
									<input type="text" name="lname" class="form-control" id="validationTooltip02" placeholder="Last name"   [(ngModel)]="model.lname" #lname="ngModel"
                  [ngClass]="{ 'is-invalid': userForm.submitted && lname.invalid, 'is-valid': userForm.submitted && !lname.invalid }" required>
									<div *ngIf="userForm.submitted && !lname.errors?.required" class="valid-tooltip">Looks good!</div>
								</div>
								<div class="col-md-4 mb-4 form-group">
									<label for="validationTooltip03">City</label>
									<input type="text" name="city" class="form-control" id="validationTooltip03" placeholder="City"  [(ngModel)]="model.city" #city="ngModel"
                  [ngClass]="{ 'is-invalid': userForm.submitted && city.invalid, 'is-valid': userForm.submitted && !city.invalid }" required>
									<div *ngIf="userForm.submitted && (city.invalid || city.errors?.required)" class="invalid-tooltip">Please provide a valid city.</div>
								</div>
							</div>
							<button class="btn btn-primary" type="submit">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
  </div>

  <div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Validation States with Tooltips (Reactive Form)</h4>
				</div>
				<div class="card-content">
					<div class="card-body">
						<p>Use <code>form.needs-validation</code> and swap <code>.&#123;valid/invalid&#125;-feedback</code> with <code>.&#123;valid/invalid&#125;-tooltip</code> to display validation feedback in a styled tooltip.</p>
						<form class="needs-validation" [formGroup]="employeeForm" (ngSubmit)="onReactiveFormSubmit()">
							<div class="form-row">
								<div class="col-md-4 mb-4 form-group">
									<label for="validationTooltip04">First name</label>
									<input type="text" formControlName="fname" class="form-control" id="validationTooltip04" placeholder="First name"
                  [ngClass]="{ 'is-invalid': submitted && f.fname.invalid, 'is-valid': submitted &&  !f.fname.invalid }" required>
									<div *ngIf="submitted && !f.fname.errors?.required" class="valid-tooltip">Looks good!</div>
								</div>
								<div class="col-md-4 mb-4 form-group">
									<label for="validationTooltip05">Last name</label>
									<input type="text" formControlName="lname" class="form-control" id="validationTooltip05" placeholder="Last name"
                  [ngClass]="{ 'is-invalid': submitted && f.lname.invalid, 'is-valid': submitted && !f.lname.invalid }" required>
									<div *ngIf="submitted && !f.lname.errors?.required" class="valid-tooltip">Looks good!</div>
								</div>
								<div class="col-md-4 mb-4 form-group">
									<label for="validationTooltip06">City</label>
									<input type="text" formControlName="city" class="form-control" id="validationTooltip06" placeholder="City"
                  [ngClass]="{ 'is-invalid': submitted && f.city.invalid, 'is-valid': submitted && !f.city.invalid }" required>
									<div *ngIf="submitted && (f.city.invalid || f.city.errors?.required)" class="invalid-tooltip">Please provide a valid city.</div>
								</div>
							</div>
							<button class="btn btn-primary" type="submit">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Tooltip validations end -->
