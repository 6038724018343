import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from '../../shared/order/order.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UrgentOrder } from '../../shared/model/urgentorder';

@Component({
  selector: 'app-urgentorders',
  templateUrl: './urgentorders.component.html',
  styleUrls: ['./urgentorders.component.scss']
})

export class UrgentordersComponent implements OnInit  {

    ngOnInit(): void {
        this.orderService.getAllUrgentOrders().subscribe( (urgentorders : any ) => {
            this.rows = [...urgentorders]  
            this.changeDetection.markForCheck()
        })
    }


  rows = [];
  selected = [];
  printeditems : any

  columns = [
    { prop: 'selected', 
    name: '', 
    sortable: false, 
    canAutoResize: false, 
    draggable: false, 
    resizable: false, 
    headerCheckboxable: true, 
    checkboxable: true,
    width: 30 
    },
    {prop : 'shopName'},
    { prop: 'productName' },
    { prop: 'color' },
    { prop: 'quantity' },
    { prop: 'isPrinted' }
  ]
   
    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(private orderService : OrderService,private changeDetection: ChangeDetectorRef,) {
        
      
        
        
    }

    
   
    printComponent(cmpName) {
        
        let printContents = document.getElementById(cmpName).innerHTML;
        let originalContents = document.body.innerHTML;
        printContents.fontsize("25px")
        document.body.innerHTML = printContents;
   
        window.print();
        document.body.innerHTML = originalContents;

        
   }

   onSelect(row) {
       this.printeditems = row.selected
      
    }

    async printList()
    {
        
        await this.orderService.urgentitemsprintstatus(this.printeditems).subscribe(x=>{
          console.log(x)
        })
        const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
            popupWin.document.open();
    
    
            popupWin.document.write(`
            <html>
            <head>
            <title>Print tab</title>
            <style>
              .striped-border {
                border: 1px dashed #000;
                width: 100%;
                margin: auto;
                margin-top: 5%;
                margin-bottom: 5%;
            }
            .box-one {
                border-radius: 10px;
                background: #BADA55;
              }
              .box-two {
                border-radius: 10px;
                border: 3px solid #BADA55;
              }
              thead {
                  display: table-row-group;
              }
            
            table {
              width: 100%;
            }
            
            table thead tr th {
              padding: 10px;
            }
            
            td,
            th {
              font-size: 20px;
              padding: 0px;
              background: #eee;
            }
            
            @page { 
              size: portrait;
              margin: 1cm;				
            }
              </style>
            
            </head>
            <body onload="window.print(); window.close()">
            <h2>Carito Urgente</h2>
            <table class="table">
        <thead>
          <tr>
            <th>Nombre de tienda</th>
            <th>Nombre de producto</th>
            <th>Color</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
         `
            );
            
           
            
        this.printeditems.forEach(element => {
        popupWin.document.write('<tr>');
        popupWin.document.write("<td>" + element.shopName + "</td><td>"+ element.productName +"</td><td>"+ element.color +"</td><td>"+ element.quantity +"</td>");
        popupWin.document.write('</tr>');    
    });
        
        popupWin.document.write(' </tbody></table>');
        popupWin.document.write('</body></html>')
        popupWin.document.close();
       
     }
        
        getRowClass(row) {
          if(row.isPrinted)
          {
              return {
                  'is-printed': true 
                }; 
          }
          
        }

        getCellClass({ row, column, value }): any {
          console.log("getcellclass")
          console.log(value)
          return {
            'is-female': value === 'female'
          };
        }
    }
    
    

