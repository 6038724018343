import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MatchHeightModule } from '../../shared/directives/match-height.directive';
import { AllOrdersComponent } from './allorders.component';
import { AllOrdersRoutingModule } from './allorders-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from 'app/forms/forms.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';



@NgModule({
    imports: [
        CommonModule,
        PipeModule,
        AllOrdersRoutingModule,
        MatchHeightModule,
        CommonModule,
        FormModule,
        ReactiveFormsModule,
        NgxDatatableModule,
    ],
    exports: [],
    declarations: [AllOrdersComponent,],
    providers: []
})
export class AllOrdersModule { }