<nav class="navbar navbar-expand-lg navbar-light bg-faded">
  <div class="container-fluid">
      <div class="navbar-header">
          <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
          </button>
      </div>
      <div class="navbar-container">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">
                  <li class="nav-item mr-2">
                      <a href="javascript:;" class="nav-link" appToggleFullscreen>                            
                          <i class="ft-maximize font-medium-3 blue-grey darken-4"></i>
                          <p class="d-none">fullscreen</p>
                      </a>
                  </li>
                  <li class="nav-item" ngbDropdown placement="bottom-right">
                      <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                          <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                          <p class="d-none">User Settings</p>
                      </a>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                         
                          <div class="dropdown-divider"></div>
                          <a [routerLink]="['/auth/logout']" class="dropdown-item" href="javascript:;">
                              <i class="ft-power mr-2"></i>
                              <span>Logout</span>
                          </a>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</nav>