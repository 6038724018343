import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MatchHeightModule } from '../../shared/directives/match-height.directive';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserListRoutingModule } from './userlist-routing.module';
import { UserslistComponent } from './userslist.component';



@NgModule({
    imports: [
        UserListRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule
    ],
    exports: [],
    declarations: [UserslistComponent],
    providers: [],
})
export class UserListModule { }