import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserbalancelistComponent } from './userbalancelist.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';



const routes: Routes = [
  {
    path: '',
     component: UserbalancelistComponent,
    data: {
      title: 'User'
    },    
    canActivate: [AuthGuard] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserBalanceListRoutingModule { }