import { Router } from '@angular/router';
import { Injectable  } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
  
  public token: string;
 
  IsAuthenticated$ = new BehaviorSubject<boolean>(false)
  
  constructor(private http: HttpClient) {
      // set token if saved in local storage
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  login(username: string, password: string) : Observable<any> {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })}

    let body = JSON.stringify({ email: username, password: password })
    
      return  this.http.post(environment.endPointUri +'/api/Auth/loginadmin', body, httpOptions );

  }

  logout(): void {
      // clear token remove user from local storage to log user out
      this.token = null;
      localStorage.removeItem('currentUser');
  }

  isAuthenticaticated() {
    // remove user from local storage to log user out
    let user = localStorage.getItem('currentUser');
    if(user){
      this.IsAuthenticated$.next(true)
    }else{
      this.IsAuthenticated$.next(false)
    }

    return this.IsAuthenticated$.asObservable()

  }

  register(registerObject) : Observable<any> {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })}
    
      return  this.http.post(environment.endPointUri +'/api/Auth/register', {"email":registerObject.inputEmail,"password": registerObject.password,"shopname":registerObject.inputShopName,"tel": registerObject.inputTelephone}, httpOptions );

  }

}
