<div class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card">
            <div class="content" id="print-section">
                    <form [formGroup]="messageForm" novalidate>
  
                        <div class="form-group">
                            <label for="comment">Titulo:</label>
                            <input class="form-control"  formControlName="title"/>
                          </div>
                        <div class="form-group">
                            <label for="comment">Message:</label>
                            <textarea class="form-control" rows="5" id="comment" formControlName="message"></textarea>
                          </div>
                         
                          <button type="submit" class="btn black btn-round btn-dark" type="submit" [disabled]="!messageForm.valid" (click) = "onSubmitPage()">Send Notfication </button>
  
                </form>
                <ngb-alert *ngIf="successMessage" type="success" (close)="staticAlertClosed = true">{{ successMessage }}</ngb-alert>
            </div>
        </div>
    </div>
  </div>