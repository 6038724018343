import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatchHeightModule } from 'app/shared/directives/match-height.directive';
import { SearchComponent } from './search.component';
import { SearchRoutingModule } from './search-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        SearchRoutingModule,
        MatchHeightModule,
        ReactiveFormsModule,
        CommonModule,
        NgxDatatableModule,
        NgbModule
    ],
    exports: [],
    declarations: [SearchComponent],
    providers: [],
})
export class SearchModule { }