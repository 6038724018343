<section _ngcontent-uxv-c40="" id="form-action-layouts">
    <div _ngcontent-uxv-c40="" class="row text-left">
        <div _ngcontent-uxv-c40="" class="col-sm-12">
            <div _ngcontent-uxv-c40="" class="content-header">BALANCE</div>
            <div _ngcontent-uxv-c40="" class="content-header">{{this.user.name  }}</div>
        </div>
    </div>
    <div _ngcontent-uxv-c40="" class="row text-left">
        <div _ngcontent-uxv-c40="" class="col-md-6">
            <div _ngcontent-uxv-c40="" class="card">
                <div _ngcontent-uxv-c40="" class="card-header">
                    <div _ngcontent-uxv-c40="" class="alert alert-info" role="alert"><strong
                            _ngcontent-uxv-c40="">AGREGAR QANTIDAD EN BALANCE.</strong></div>
                    <form [formGroup]="userForm" novalidate>

                        <fieldset _ngcontent-uxv-c25="" class="form-group">
                            <label _ngcontent-uxv-c25="" for="basicTextarea">Basic Textarea</label>
                            <textarea _ngcontent-uxv-c25="" class="form-control" id="basicTextarea" rows="3"
                                formControlName="note"></textarea>
                        </fieldset>
                        <div class="form-group">
                            <label>Qantidad</label>
                            <input type="text" class="form-control border-input" placeholder="amount"
                                formControlName="amount">
                        </div>
                        <button type="submit" class="btn black btn-round btn-dark" type="submit"
                            [disabled]="!userForm.valid" (click)="onSubmitPage()">Guardar </button>
                    </form>
                </div>
            </div>
        </div>
        <div _ngcontent-uxv-c40="" class="col-md-6">
            <div _ngcontent-uxv-c40="" class="card">
                <div _ngcontent-uxv-c40="" class="card-header">
                    <div _ngcontent-uxv-c40="" class="alert alert-info" role="alert"><strong
                            _ngcontent-uxv-c40="">BALANCE ACTIVO.</strong></div>

                    <div _ngcontent-uxv-c40="" *ngIf="activebalance | async as activeb">
                        <label _ngcontent-uxv-c25="">Balance Total : {{activeb.totalBalance}}</label><br>
                        <label _ngcontent-uxv-c25="">Fecha De Inicio Balance:
                            {{activeb.customerBalanceDateTime | date}}</label>
                        <ul _ngcontent-uxv-c76="" class="list-group" *ngFor="let activebalance of activeb.balanceItems">
                            <li _ngcontent-uxv-c76="" class="list-group-item" *ngIf="!activebalance.isDeleted">

                                <span _ngcontent-uxv-c76="" class="badge badge-danger mr-2"
                                    *ngIf="activebalance.balanceItemAmount < 0">
                                    {{activebalance.balanceItemAmount}}</span>
                                <span _ngcontent-uxv-c76="" class="badge badge-success mr-2"
                                    *ngIf="activebalance.balanceItemAmount >= 0">
                                    {{activebalance.balanceItemAmount}}</span>


                                <div _ngcontent-uxv-c76="" class="d-flex w-100 justify-content-between">
                                    <h6 _ngcontent-uxv-c76="" class="mb-3">{{activebalance.note}}</h6>
                                    <small
                                        _ngcontent-uxv-c76="">{{activebalance.balanceItemDateTime | date : 'dd/MM/yyyy'}}</small>
                                </div>

                                <button _ngcontent-uxv-c78="" class="btn btn-raised mr-1 btn-round btn-primary btn-sm"
                                    (click)="deletebalanceitem(activebalance)">DELETE</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
            
</section>
<section>
 
        <ngx-datatable #myTable
        class="material expandable" style="height: 700px;"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="50"
        [scrollbarV]="true"
        [rows]="rows"
        (page)="onPage($event)"
      >
        <!-- Row Detail Template -->
        <ngx-datatable-row-detail rowHeight="100" #myDetailRow (toggle)="onDetailToggle($event)">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <div style="padding-left:35px; ">
              <ul class="list-group" *ngFor="let activebalance of row.balanceItems">
                            <li 
                                *ngIf="!activebalance.isDeleted">
                                {{activebalance.note}}
                                <span class="badge badge-success badge-pill">Balance :
                                    {{activebalance.balanceItemAmount}} Fecha :
                                    {{activebalance.balanceItemDateTime | date : 'dd/MM/yyyy'}}</span>
                            </li>
                        </ul>
            </div>
           
          </ng-template>
        </ngx-datatable-row-detail>
        <!-- Column Templates -->
        <ngx-datatable-column
          [width]="50"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a
              href="javascript:void(0)"
              [class.datatable-icon-right]="!expanded"
              [class.datatable-icon-down]="expanded"
              title="Expand/Collapse Row"
              (click)="toggleExpandRow(row)"
            >
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Balance Total" width="80">
          <ng-template let-row="row" let-totalBalance="totalBalance" ngx-datatable-cell-template>
            <strong>{{ row.totalBalance }}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Balance Date" width="80">
          <ng-template  let-row="row"   let-customerBalanceDateTime="customerBalanceDateTime" ngx-datatable-cell-template>
            <strong>{{ row.customerBalanceDateTime  | date : 'dd/MM/yyyy'}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column   name="Estado" width="80">
          <ng-template let-row="row" let-balanceAplicado="balanceAplicado" ngx-datatable-cell-template >
            <div class="box"  [style.background-color]="row.balanceAplicado ?'green' : 'red' "></div>
            
          </ng-template>
        </ngx-datatable-column>
        
      </ngx-datatable>


  
</section>