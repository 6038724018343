import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Config } from 'configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BalanceVm } from 'app/models/balanceVm';
import { BalanceItemVm } from 'app/models/balanceItemVm';



@Injectable({
  providedIn: 'root'
})
export class BalanceService {

 
  constructor(private http : HttpClient) { }

  
  getActaveBalance (userid) : Observable<BalanceVm>
  {
    debugger
    // add authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('currentUser'))
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.get<BalanceVm>(environment.endPointUri +'/api/balance/getactivebalance?userid='+userid, httpOptions );
  }
 

  getAllBalance (userid)
  {
    // add authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('currentUser'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.get(environment.endPointUri +'/api/balance/getallbalance?userid='+userid, httpOptions );
  }

  addBalanceItem(balanceItem)
  {
      debugger
    let user = JSON.parse(localStorage.getItem('currentUser'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.post(environment.endPointUri +'/api/balance/addbalanceitem',balanceItem, httpOptions );
  }
  deleteBalanceItem(balanceitem : BalanceItemVm , userid){
    let user = JSON.parse(localStorage.getItem('currentUser'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.get(environment.endPointUri +'/api/balance/removebalanceitem?itemid='+balanceitem.balanceItemId+'&userid='+userid, httpOptions );
  }


  //getallusersbalance

  getAllCustomerBalance(){
    let user = JSON.parse(localStorage.getItem('currentUser'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' + user.token
      })}

      return  this.http.get(environment.endPointUri +'/api/balance/getallusersbalance', httpOptions );
  }
}