<div class="row">
  <div class="col-12">
    <div class="content-header">Checkbox</div>
  </div>
</div>

<!-- Basic Checkbox starts -->
<section id="basic-checkbox">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Basic</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <p>Add <code>.checkbox</code> as a single wrapper and add <code>&lt;label&gt;</code> with <code>for</code> attribute to get better output.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox">
                  <input type="checkbox" id="checkbox1" checked>
                  <label for="checkbox1"><span>Active</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox">
                  <input type="checkbox" id="checkbox2">
                  <label for="checkbox2"><span>Inactive</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox">
                    <input type="checkbox" id="checkbox3" disabled checked>
                    <label for="checkbox3"><span>Checked - disabled</span></label>
                </div>
              </li>
             <li class="d-inline-block">
                <div class="checkbox">
                    <input type="checkbox" id="checkbox4" disabled>
                    <label for="checkbox4"><span>Unchecked - disabled</span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Basic Checkbox ends -->

<!-- Custom checkbox starts -->
<section id="custom-checkbox">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Custom</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
             <p>Add <code>.custom-control.custom-checkbox</code> as a single wrapper and add <code>.custom-control-label</code> for better output.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2 mb-2">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" checked name="customCheck" id="custom-check-1">
                  <label class="custom-control-label" for="custom-check-1"><span>Active</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" name="customCheck" id="custom-check-2">
                  <label class="custom-control-label" for="custom-check-2"><span>Inactive</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" checked name="customCheck" id="custom-check-3" disabled>
                  <label class="custom-control-label" for="custom-check-3"><span>Active - disabled</span></label>
                </div>
              </li>
              <li class="d-inline-block">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" name="customCheck" disabled id="custom-check-4">
                  <label class="custom-control-label" for="custom-check-4"><span>Inactive - disabled</span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Custom checkbox ends -->

<!-- Colored checkbox starts -->
<section id="colored-checkbox">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Colored</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <p>Add <code>.checkbox.checkbox-&#123;color&#125;</code> in the wrapper for colored checkbox.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox checkbox-primary">
                  <input type="checkbox" id="color-checkbox-1" checked>
                  <label for="color-checkbox-1"><span>Primary</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox checkbox-secondary">
                  <input type="checkbox" id="color-checkbox-2" checked>
                  <label for="color-checkbox-2"><span>Secondary</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox checkbox-success">
                  <input type="checkbox" id="color-checkbox-3" checked>
                  <label for="color-checkbox-3"><span>Success</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox checkbox-danger">
                  <input type="checkbox" id="color-checkbox-4" checked>
                  <label for="color-checkbox-4"><span>Danger</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox checkbox-warning">
                  <input type="checkbox" id="color-checkbox-5" checked>
                  <label for="color-checkbox-5"><span>Warning</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox checkbox-info">
                  <input type="checkbox" id="color-checkbox-6" checked>
                  <label for="color-checkbox-6"><span>Info</span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Colored checkbox ends -->

<!-- Size starts -->
<section id="size-checkbox">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Sizes</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <p>Use <code>.checkbox.checkbox-sm</code> in the wrapper for small checkbox. Colored small checkbox can also be used.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox checkbox-sm">
                  <input type="checkbox" id="size-sm" checked>
                  <label for="size-sm"><span>Small</span></label>
                </div>
              </li>
              <li class="d-inline-block mr-2 mb-2">
                <div class="checkbox">
                  <input type="checkbox" id="size-default">
                  <label for="size-default"><span>Default</span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Size ends -->
