import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { OrderListRoutingModule } from './orderlist-routing.module';
import { MatchHeightModule } from '../../shared/directives/match-height.directive';
import { OrderlistComponent } from './orderlist.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PipeModule } from 'app/shared/pipes/pipe.module';


@NgModule({
    imports: [
        OrderListRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule,
        PipeModule
    ],
    exports: [],
    declarations: [OrderlistComponent],
    providers: [],
})
export class OrderListModule { }