<!-- Filter Datatable Options Starts -->
<div class="container-fluid">
    <section class="row">
        <div class="col-md-8">
            <h1>Buscar</h1>
        </div>
        <div class="col-md-4">
            <div class="btn-group float-right mt-2" role="group">
              <button class="btn" (click)="exportAsXLSX()"><i class="fa fa-file-excel-o"></i>Exportar</button>
            </div>
        </div>
    </section>
</div>
<section id="filter" class="mb-3">
   
  <div class="row">
      <div class="col-12">
          <form [formGroup]="searchForm" (ngSubmit)="onSubmit()"  novalidate >
                    <div class="position-relative has-icon-right">
                        <input type="text" class="form-control round" placeholder="Search" formControlName="searchText" >
                      </div>
          </form>    
      </div>
  </div>
  <div class="row">
      <div class="col-sm-12">
            
          
          <ngx-datatable #table class='bootstrap' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
              [rowHeight]="'auto'" [rowClass]="getRowClass" [limit]="50" [rows]='rows' (activate)='onUserEvent($event)'  >
              <ngx-datatable-column name="orderNumber"></ngx-datatable-column>
              <ngx-datatable-column name="shopName"></ngx-datatable-column>
              <ngx-datatable-column name="orderDate">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date: 'dd/MM/yyyy'}}
                </ng-template>
              </ngx-datatable-column>
             
              <ngx-datatable-column name="Total"  ></ngx-datatable-column>
              <ngx-datatable-column  name="isPrinted">
                    <ng-template ngx-datatable-header-template>
                            <span>Estado</span>
                    </ng-template>  
                <ng-template let-row="row" ngx-datatable-cell-template>          
  
                      <div class="printed" *ngIf="row.isPrinted;else other_content">
                          IMPRIMIDO
                      </div>
                      
                      <ng-template #other_content >
                        <p class="noprinted">NO IMPRIMIDO</p></ng-template>
                    </ng-template>
                </ngx-datatable-column>

                <ng-template let-row="row" ngx-datatable-cell-template>          
  
                    <div style="background: red;" *ngIf="row.totalBalance < 0 ; else other_content">
                        {{row.totalBalance}}
                    </div>
                    
                    <ng-template #other_content >
                      <p style="background:green"> {{row.totalBalance}}</p></ng-template>
                  </ng-template>
            </ngx-datatable>
            <ngb-alert *ngIf="successMessage" type="success" (close)="staticAlertClosed = true">{{ successMessage }}</ngb-alert>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 8px; float:left;">
           
            <h4><strong>TOTAL SUM: {{total}}</strong></h4>
        </div>
  </div>
</section>