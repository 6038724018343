<div class="row">
  <div class="col-12">
    <div class="content-header">Radios</div>
  </div>
</div>

<!-- Basic Radios starts -->
<section id="basic-radio">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Basic</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <p>Add <code>.radio</code> as a single wrapper and add <code>&lt;label&gt;</code> with <code>for</code> attribute to get better output.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2">
                <div class="radio">
                  <input type="radio" name="basic-radio-1" id="radio1" checked>
                  <label for="radio1">Active</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio">
                  <input type="radio" name="basic-radio-1" id="radio2">
                  <label for="radio2">Inactive</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio">
                  <input type="radio" id="radio3" disabled checked>
                  <label for="radio3">Active - Disabled</label>
                </div>
              </li>
              <li class="d-inline-block">
                <div class="radio">
                  <input type="radio" id="radio4" disabled>
                  <label for="radio3">Inactive - Disabled</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Basic Radios ends -->

<!-- Custom Radios starts -->
<section id="custom-buttons">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Custom</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <p>Add <code>.custom-control.custom-radio</code> as a single wrapper and add <code>.custom-control-label</code> for better output.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2">
                <fieldset>
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" name="custom-radio" id="customRadio1" checked="">
                    <label class="custom-control-label" for="customRadio1">Active</label>
                  </div>
                </fieldset>
              </li>
              <li class="d-inline-block mr-2">
                <fieldset>
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" name="custom-radio" id="customRadio2">
                    <label class="custom-control-label" for="customRadio2">Inactive</label>
                  </div>
                </fieldset>
              </li>
              <li class="d-inline-block mr-2">
                <fieldset>
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" checked="" id="customRadio3" disabled="">
                    <label class="custom-control-label" for="customRadio3">Active - disabled</label>
                  </div>
                </fieldset>
              </li>
              <li class="d-inline-block">
                <fieldset>
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="customRadio4" disabled="">
                    <label class="custom-control-label" for="customRadio4">Inactive - disabled</label>
                  </div>
                </fieldset>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Custom Radios ends -->

<!-- Colored Radios starts -->
<section id="colored-radio">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Colored</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <p>Add <code>.radio.radio-&#123;color&#125;</code> in the wrapper for colored radio.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2">
                <div class="radio radio-primary">
                  <input type="radio" name="color-radio" id="color-radio-1" checked>
                  <label for="color-radio-1">Primary</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio radio-secondary">
                  <input type="radio" name="color-radio" id="color-radio-2">
                  <label for="color-radio-2">Secondary</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio radio-success">
                  <input type="radio" name="color-radio" id="color-radio-3">
                  <label for="color-radio-3">Success</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio radio-danger">
                  <input type="radio" name="color-radio" id="color-radio-4">
                  <label for="color-radio-4">Danger</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio radio-warning">
                  <input type="radio" name="color-radio" id="color-radio-5">
                  <label for="color-radio-5">Warning</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio radio-info">
                  <input type="radio" name="color-radio" id="color-radio-6">
                  <label for="color-radio-6">Info</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Colored Radios ends -->

<!-- Sizes starts -->
<section id="size-radio">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Sizes</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <p>Use <code>.radio.radio-sm</code> in the wrapper for small radio. Colored small radio can also be used.</p>
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block align-top mr-2">
                <div class="radio radio-sm">
                  <input type="radio" name="size-radio-1" id="size-sm" checked>
                  <label for="size-sm">Small</label>
                </div>
              </li>
              <li class="d-inline-block">
                <div class="radio">
                  <input type="radio" name="size-radio-1" id="size-default">
                  <label for="size-default">Default</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sizes ends -->
