import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MatchHeightModule } from '../../shared/directives/match-height.directive';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserComponent } from './user.component';
import { UserRoutingModule } from './user-routing.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
    imports: [
        UserRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [UserComponent],
    providers: [],
})
export class UserModule { }