import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenService } from 'app/shared/token/token.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  messageForm: FormGroup;
  successMessage : string 
  staticAlertClosed
  private _success = new Subject<string>()
  tokens =  []
  constructor(private fb: FormBuilder,private tokenservice : TokenService) { }

  ngOnInit() {
    this._success.subscribe((message) => this.successMessage = message);
    this._success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);

    this.messageForm = this.fb.group({
      message:["",Validators.required],
      title:["",Validators.required]
    });
    
    this.tokenservice.getTokens().valueChanges().subscribe(( x : any )=>{
      console.log(x)
      
       
      Object.keys(x).forEach(y =>{

        let tok
        if(x[y].pushToken)
        {
          this.tokens.push(x[y].pushToken)
          return;
        }
      
      })
    })
  }

  onSubmitPage()
  {
    if(this.messageForm.get('message').value)
    {
      
      console.log(this.messageForm.get('message').value)
      console.log(this.messageForm.get('title').value)
     

      this.tokenservice.sendNotification(['clYMy3sXUDM:APA91bEHrlY_7G3oC0Wwr4RFdH4r2jGwLXusGLx7_R-oARbQr1mwORWOlWXJsP2leVB0moWY_nB_YT37q22EadXezwglOVb3cfELKYrKSLHzGMcEtnxf3U4ZqSqt1eybER1ddBsIBz-b'],this.messageForm.get('message').value,this.messageForm.get('title').value)
      .subscribe( (x : any)=> {
        console.log(x.success)

        this._success.next(`tu notificación fue enviada a ${x.success} usuarios`)
        //show some message
      }, error =>{
        this._success.next(`Tu notificación no fue enviada exito.Contacta con admin`)
        console.log(error)
        //sho some error
      })
    }
  }
}
