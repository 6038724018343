<div class="row">
  <div class="col-lg-12 col-md-12">
      <div class="card">
          <div class="content" id="print-section">
                  <form [formGroup]="userForm" novalidate>

                      <div class="form-group">
                          <label >Email address</label>
                          <input type="text" class="form-control border-input"  placeholder="Email" formControlName="email" readonly>
                        </div>
                        <div class="form-group">
                            <label >Name</label>
                            <input type="text" class="form-control border-input"  placeholder="Name" formControlName="name" >
                          </div>
                          <div class="form-group">
                              <label >UserName</label>
                              <input type="text" class="form-control border-input"  placeholder="UserName" formControlName="username" >
                            </div>
                        <button type="submit" class="btn black btn-round btn-dark" type="submit" [disabled]="!userForm.valid" (click) = "onSubmitPage()">Guardar </button>

              </form>
            
          </div>
      </div>
  </div>
</div>
