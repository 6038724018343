import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FbUser } from 'app/models/user';
import { UserService } from 'app/shared/user/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(private router: Router , private fb: FormBuilder,private userservice : UserService,private route: ActivatedRoute,private componentFactoryResolver: ComponentFactoryResolver) { }
  id
  user : FbUser

  userForm: FormGroup;
  
  ngOnInit(): void {

    this.userForm = this.fb.group({
      email:["",Validators.required],
      name:["", Validators.required],
      username: ["" , Validators.required]
    });
    this.route.params.subscribe(params => {
      this.id = params['id']; 

      this.userservice.getUser(this.id).subscribe( (user : FbUser ) => {
        console.log(user)
      this.user = user
        this.userForm.setValue({"email": user.email,"name" : user.name, "username": user.username})
      },error => {
        console.log(error)
      })
 
   });

  

  }

  onSubmitPage()
  {
    
    if(this.user)
    {
      this.user.name = this.userForm.get('name').value 
      this.user.username = this.userForm.get('username').value 
      this.userservice.updateUser(this.user.userId,this.user).then(x=>{
        this.router.navigate(['users']);
      })
        
    }
   
  }
}
