
import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderService } from '../../shared/order/order.service';
import { Router } from '@angular/router';
import { Page } from 'app/models/page';
import { ExcelService } from 'app/shared/export/excelservice';
import { DatatableComponent } from '@swimlane/ngx-datatable';



@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.scss']
})
export class OrderlistComponent implements OnInit {
  rows = [];
  page : Page  = new Page()
  columns = [
    { prop: 'orderDate' },
    { prop: 'isPrinted' },
    {prop : 'totalBalance'},
    { prop: 'Total' }
];

@ViewChild(DatatableComponent) table: DatatableComponent;

constructor(private orderService : OrderService,
  private changeDetection: ChangeDetectorRef,
  private router: Router,private excelservice : ExcelService) {
  this.page.pageNumber = 0;
}

ngOnInit(): void {
  this.setPage({ offset:0});
  }

 
    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        // filter our data
        const temp = this.rows.filter(function (d) {
            if(d.shopName == null)
                d.shopName = "Empty"
            return d.shopName.toLowerCase().indexOf(val) !== -1 || !val;
        });

        // update the rows
        this.rows = temp;
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 1;
    }
    
    onUserEvent ( e ) {
        if ( e.type == "click" ) {

            this.router.navigate(['orderdetails/'+e.row['orderNumber']]);
        
        }
    } 

    getRowClass(row) {
        return {
          'is-printed': (row.isPrinted % 10) === 0
        };
      }
      getCellClass({ row, column, value }): any {
        return {
          'is-printed': value === 'true'
        };
      }

      /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo){
    this.page.pageNumber = pageInfo.offset;
    this.orderService.getAllOrderswithpage(this.page).subscribe( (data : any) => {
      this.page =data.page
      this.page.pageNumber = this.page.pageNumber-1
      this.rows = [...data.rows];
      this.changeDetection.markForCheck()
    });
  }

  exportAsXLSX(){
    this.excelservice.exportAsExcelFile(this.rows, "historialdepedidos")
  }
}