import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { OrderdetailsComponent } from '../orderdetails/orderdetails.component';
import { MatchHeightModule } from '../../shared/directives/match-height.directive';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OrderDetailsRoutingModule } from './orderdetails-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorderdetailsComponent } from '../editorderdetails/editorderdetails.component';
import { AddorderdetailsComponent } from '../addorderdetails/addorderdetails.component';



@NgModule({
    imports: [
        CommonModule,
        OrderDetailsRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [OrderdetailsComponent,EditorderdetailsComponent,AddorderdetailsComponent],
    providers: [],
})
export class OrderDetailsModule { }