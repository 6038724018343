import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterContentInit, QueryList, ViewChildren, Type } from '@angular/core';
import { OrderDetailsVm, Orderdetailsvm } from '../../shared/model/orderdetails';
import { OrderService } from '../../shared/order/order.service';
import { ActivatedRoute } from '@angular/router';
import { EditorderdetailsComponent } from '../editorderdetails/editorderdetails.component';
import { AddorderdetailsComponent } from '../addorderdetails/addorderdetails.component';
import { Product } from 'app/shared/model/product';
import { BalanceService } from 'app/balancedeusario/balance.service';
import { Observable } from 'rxjs';
import { BalanceVm } from 'app/models/balanceVm';

@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.scss']
})
export class OrderdetailsComponent implements OnInit,AfterContentInit {
  
  components = [];
  activebalance = new Observable<BalanceVm>(); 

  ngAfterContentInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.orderservice.getOrderdetails(this.id).subscribe( (orderdetails : OrderDetailsVm) => {
      
      this.orderdetails = orderdetails
      console.log(this.orderdetails)
      this.activebalance = this.balanceserivce.getActaveBalance(this.orderdetails.userId)
      this.calculatetotal()
 
      },error => {
      })
 
   });
  }

  orderdetails : OrderDetailsVm
  total : number = 0
  id
  price
  products  = new Array<Product>();
  @ViewChild(EditorderdetailsComponent) editchild : EditorderdetailsComponent;
  @ViewChildren(OrderdetailsComponent) private _childrenitems: QueryList<OrderdetailsComponent>;
  orderstatious = ['pending' , 'done', 'cancled']
  
  
  @ViewChild("alertContainer", { read: ViewContainerRef }) container;

  constructor(
    private orderservice : OrderService,
    private route: ActivatedRoute,
    private balanceserivce : BalanceService,
    private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {

 
    
 }


 trackByIndex(index: number, obj: any): any {
  return index;
}

onSubmit()
{
  this.orderservice.postOrderdetails(this.orderdetails.orderdetails).subscribe(x => {
    location.reload()
  }, error => {

    console.log(error)

  })

}

loadOrderdetailsToEdit(orderdetailsid, product){
  
  this.editchild.setReparationForm(orderdetailsid,product)
}
loadOrderdetailsToAddNew(orderdetailsid){
  
  this.editchild.addReparationForm(orderdetailsid)
}
loadOrderdetailsToDelete(orderdetailsid, product){
  
  this.editchild.deleteReparationForm(orderdetailsid,product)
}

  calculatetotal(){

    this.total = 0 
    this.orderdetails.orderdetails.forEach( (od : Orderdetailsvm ) => {
      this.total = Number (this.total) + (Number(od.product.price) * od.product.quantity) 
    });

  }


  print(): void {
    
    //call to print server to change statious send this.id
    this.orderservice.orderisPrinted(this.id).subscribe(x => {
      console.log(x)
    },error => {
      console.log(error)
    })


    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          .striped-border {
            border: 1px dashed #000;
            width: 100%;
            margin: auto;
            margin-top: 5%;
            margin-bottom: 5%;
        }
        .right{
          position: absolute;
          left: 20px;
          width: 300px;
          height: 30px;
          font-weight: 900;
          text-decoration : underline;
          font-size: 150%;
          
          
          }
          thead {
              display: table-row-group;
          }
        
        table {
          width: 100%;
        }
        
        table thead tr th {
          padding: 10px;
        }
        
        td,
        th {
          font-size: 17px;
          padding: 0px;
          background: #eee;
        }
        
        @page { 
          size: portrait;
          margin: 1cm;				
        }
        @media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}
  


orderstatuious(statious)
{
console.log(statious)
}


addItemDy()
{
  
  const factory = this.componentFactoryResolver.resolveComponentFactory(AddorderdetailsComponent);
  const componentRef : any = this.container.createComponent(factory);
  componentRef.instance.message = {};
  this.components.push(componentRef)
}

viewChildren()
{
  this.products = []
  this.components.forEach(x => {
    console.log(x.instance.orderForm.controls) 
    let productname = x.instance.orderForm.controls['productName'].value
    let quantity  = x.instance.orderForm.controls['quantity'].value
    let price   = x.instance.orderForm.controls['price'].value
    let color = x.instance.orderForm.controls['color'].value
    
    if(productname)
    {
      
      let product  = new Product(productname)
      product.quantity = this.checkNumber(quantity)
      product.price = this.checkNumber(price)
      product.color = color
      this.products.push(product)
    }
    else
    {
      alert("Nombre de Producto esta vacio ")
    }
   
  })
  //send products to service
  console.log(this.products.length)
  if(this.products.length > 0)
  {
    let list = { "orderid" : this.id , "productlist" : this.products}
    this.orderservice.addorderlistItems(list).subscribe(x=>{
      location.reload()
    },error => {
      alert("some error")
    })
  }

  
}

aplicarBlance(balance : BalanceVm){

  if(this.total <= 0){

    alert("TOTAL EN NEGATIVO NO SE PUEDE APLICAR BALANCE")

  }
  else{
    let products = new Array()
  let product  = new Product("Balance Aplicado "+ this.getdate())
      product.quantity = this.checkNumber(1)
      product.price = this.checkNumber(balance.totalBalance)
      product.color = "Balance"
      products.push(product)

      if(products.length > 0)
  {
    let list = { "orderid" : this.id , "productlist" : products , userId : this.orderdetails.userId}
    this.orderservice.addorderlistItemsBalance(list).subscribe(x=>{
      location.reload()
    },error => {
      alert("some error")
    })
  }
  }
  

}

rem()
{
  this.removeComponent(AddorderdetailsComponent)
}
removeComponent(componentClass: Type<any>) {
  // Find the component
 
 // const component = this.components.find((component) => component.instance instanceof componentClass);
  const component = this.components[this.components.length-1]
  const componentIndex = this.components.indexOf(component);

  if (componentIndex !== -1) {
    // Remove component from both view and array
    this.container.remove(this.container.indexOf(component));
    this.components.splice(componentIndex, 1);
    
  }
}

updatequantity(product){
debugger
  if(product)
  this.orderservice.editOrderDetails(product).subscribe(x => {
    location.reload();
  },
  (error : any) => {
         
    if (error.status === 401 || error.status === 403)  {
      alert("some error")
      
    }

  })
}

checkNumber(_number)
{
  let n = +_number
  if(Number.isNaN(n))
  {
    return 0
  }
  else{
    return n
  }

}

getdate(){
  var today = new Date();
var dd = String(today.getDate());
var mm = String(today.getMonth() + 1); //January is 0!
var yyyy = today.getFullYear();

return dd + '/' + mm + '/' + yyyy;
}
}

