import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderService } from '../../shared/order/order.service';
import { Orderdetailsvm } from '../../shared/model/orderdetails';
import { Orderdetailsadd } from '../../shared/model/orderdetailsadd';

@Component({
  selector: 'edit-order-details',
  templateUrl: './editorderdetails.component.html',
  styleUrls: ['./editorderdetails.component.scss']
})
export class EditorderdetailsComponent implements OnInit {

    orderForm: FormGroup;
    private _orderdetails : Orderdetailsvm
    orderdetailasid : any
    product : any

    @Input('id') id
    orderid
    config = {
        type: 'success',
        title: 'Successfully Saves',
        content: 'Operations Guardada',
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        position: ["bottom", "left"]
    }
    

 constructor(
    private orderservice : OrderService, 
    private fb: FormBuilder,  )
  {
      this.createForm();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(){

  }
  ngOnChanges() {

    console.log(this.id)
    this.orderid = this.id
  }
  
  createForm()
  {
      this.orderForm = this.fb.group({
        productName:['',Validators.required],
        quantity:['', Validators.required],
        price: ['', Validators.required],
        color:['' , Validators.required]
      });
      
  }

  onSubmitPage()
  {
    if(this._orderdetails)
    {
      this._orderdetails.product.productName = this.orderForm.get('productName').value 
      this._orderdetails.product.quantity = this.orderForm.get('quantity').value 
      this._orderdetails.product.price = this.orderForm.get('price').value
      this._orderdetails.product.color = this.orderForm.get('color').value

      this.orderservice.editOrderDetails(this._orderdetails).subscribe(x => {
        location.reload();
      },
      (error : any) => {
             
        if (error.status === 401 || error.status === 403)  {
          alert("some error")
          
        }
      
    },
    ()=>{
        this.orderForm.reset()
    })
    }
    else
    {
     var _orderdetailsadd : Orderdetailsadd =
      {
          productName : this.orderForm.get('productName').value,
          color : this.orderForm.get('color').value,
          quantity : this.orderForm.get('quantity').value,
          price : this.orderForm.get('price').value,
          orderId : this.orderid
      }
     
    
      this.orderservice.addOrderDetails(_orderdetailsadd).subscribe(x => {
        location.reload();
      },
      (error : any) => {
             
        if (error.status === 401 || error.status === 403)  {
          alert("some error")
          
        }
      
    },
    ()=>{
        this.orderForm.reset()
    })
    }
   
  }
 
  
 


  setReparationForm(orderdetailsid : any, product: any)
  {
        this._orderdetails  =  {  id : orderdetailsid, product : product }
        this.orderForm.reset()

        this.orderForm.controls['productName'].setValue(this._orderdetails.product.productName)
        this.orderForm.controls['quantity'].setValue(this._orderdetails.product.quantity)
        this.orderForm.controls['price'].setValue(this._orderdetails.product.price)
        this.orderForm.controls['color'].setValue(this._orderdetails.product.color)
       
        
  }
  addReparationForm(orderdetailsid : any)
  {
        this.orderForm.reset()
        this.orderdetailasid = orderdetailsid

  }
  deleteReparationForm(orderdetailsid : any, product: any)
  {
    this._orderdetails  =  {  id : orderdetailsid, product : product }
    this.orderForm.reset() 
    this.orderservice.deleteOrderDetails(this._orderdetails).subscribe(x => {
      //some message and go to order
      location.reload();
    },
    (error : any) => {
           
      if (error.status === 401 || error.status === 403)  {
        alert("some error")
        
      }
    
  },
  ()=>{
      this.orderForm.reset()
  })
  }
}
