import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { OrderService } from 'app/shared/order/order.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject, of, from } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExcelService } from 'app/shared/export/excelservice';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent  {
  public loading = false;
  private _success = new Subject<string>()
  successMessage : string
  rows : any = [];
  total 
    // Table Column Titles
    columns = [
        { prop: 'orderNumber' },
        { prop: 'orderDate' },
        { prop: 'isPrinted' },
        { prop: 'totalBalance' },
        { prop: 'Total' }
    ];

    @ViewChild(DatatableComponent) table: DatatableComponent;
    searchForm : FormGroup
    constructor(private formBuilder: FormBuilder ,private changeDetection: ChangeDetectorRef, private orderService : OrderService,private router: Router,private excelservice : ExcelService) {
        
      this.searchForm = this.formBuilder.group({
        'searchText': ['',Validators.required]
      });
    }
    ngOnInit() 
    {
      this._success.subscribe((message) => this.successMessage = message);
      this._success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);


    this.rows = JSON.parse(localStorage.getItem("items"))
    }
    
    onUserEvent ( e ) {
        if ( e.type == "click" ) {

            this.router.navigate(['orderdetails/'+e.row['orderNumber']]);
        
        }
    } 

    getRowClass(row) {
        return {
          'is-printed': (row.isPrinted % 10) === 0
        };
      }
      getCellClass({ row, column, value }): any {
        return {
          'is-printed': value === 'true'
        };
      }

      onSubmit()
      {
        this.loading = true
        this.total = 0
        //this.rows = [];
        console.log(this.searchForm.value.searchText)
        this.orderService.search(this.searchForm.value.searchText).subscribe((x : any) =>{
          if(x.length > 0)
          {
            this.rows = [...x]
            localStorage.setItem("items" , JSON.stringify(this.rows))
            from(x).subscribe((x:any) => {
              this.total+=x.total
            })
            this.changeDetection.markForCheck()
          }
          else
          {
            this.rows=[]
            this._success.next(`No existe ninguna informacion con esa numeracion`)
          }
          
        },error =>{
          this.loading =false
          this.rows=[]
          this._success.next(`Se ha producido un error`)
          
        },
        ()=>{
          this.loading = false
        })
        
      }

      exportAsXLSX(){
        this.excelservice.exportAsExcelFile(this.rows, "historialdepedidos")
      }
}
