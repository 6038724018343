import { Component, OnInit, ViewChild } from '@angular/core';
import { FbUser } from 'app/models/user';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { BalanceService } from './balance.service';
import { BalanceItem } from 'app/models/balanceitem';
import { map, switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { BalanceVm } from 'app/models/balanceVm';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { UserService } from 'app/shared/user/user.service';

@Component({
  selector: 'app-balancedeusario',
  templateUrl: './balancedeusario.component.html',
  styleUrls: ['./balancedeusario.component.scss']
})
export class BalancedeusarioComponent implements OnInit {

  user : FbUser
  userForm: FormGroup;

  userid
  activebalance = new Observable<BalanceVm>(); 
  balancelist


  @ViewChild('myTable') table: any;

  rows: any[] = [];
  expanded: any = {};
  timeout: any;
 
  ColumnMode = ColumnMode;



  
  constructor(private fb: FormBuilder,
    private userservice : UserService,
    private activated : ActivatedRoute,private balanceserivce : BalanceService) {
    this.activated.params.pipe(map(x=>x),switchMap(x=>{
      this.activebalance = this.balanceserivce.getActaveBalance(x['id'])
    
      this.getAllBalances(x['id'])
      return of(x)
    })).subscribe(params => {
      this.userid = params['id'];
     
      });

   }

  ngOnInit() {

    this.userForm = this.fb.group({
      note :["",Validators.required],
      amount :["",Validators.required],
     
    });

     this.userservice.getUser(this.userid).subscribe( (x : any) =>{
       this.user = x
     })
  }

  onSubmitPage(){
    
    
     
    let balanceitem : BalanceItem =  
    {  
      BalanceItemAmount : +this.userForm.get('amount').value ,
      UserId : this.userid,
      Note : this.userForm.get('note').value
    }

    this.balanceserivce.addBalanceItem(balanceitem).subscribe(x=>{
      location.reload();
      },
      (error : any) => {
             
        if (error.status === 401 || error.status === 403)  {
          alert("some error")
          
        }
    })
  }


  deletebalanceitem(activebalance){
    this.balanceserivce.deleteBalanceItem(activebalance , this.userid).subscribe(x=>{
      location.reload();
    },
    (error : any) => {
           
      if (error.status === 401 || error.status === 403)  {
        alert("some error")
        
      }
    })
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  getAllBalances(userid){
    this.balanceserivce.getAllBalance(userid).subscribe( (balances : any) =>{
      console.log(balances)
      this.rows = balances
    })
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

}
