import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { UserBalanceListRoutingModule } from './userbalancelist-routing.module';
import { MatchHeightModule } from 'app/shared/directives/match-height.directive';
import { UserbalancelistComponent } from './userbalancelist.component';



@NgModule({
    imports: [
        UserBalanceListRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [UserbalancelistComponent],
    providers: [],
})
export class UserBalanceListModule { }