export class Product {

    constructor(productname, quantity = 0 )
    {
        this.productId = this.generateId()
        this.productName= productname
        this.quantity = quantity
    }
    productId: number;
    productName: string;
    quantity: number;
    updatedquantity? : number = 0
    price : Number
    color : string
    private generateId()
    {
       return Math.floor(Math.random() * 99) + 1  
    }
  }