import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NotificationRoutingModule } from './notification-routing.module';
import { MatchHeightModule } from 'app/shared/directives/match-height.directive';
import { NotificationComponent } from './notification.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
    imports: [
        NotificationRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        NgbModule
    ],
    exports: [],
    declarations: [NotificationComponent],
    providers: [],
})
export class NotificationModule { }