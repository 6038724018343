import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import 'rxjs/add/operator/take';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  tasks: AngularFirestoreCollection<any>;

  constructor(public db: AngularFireDatabase) {}


  getUsers() {

    return  this.db.list('accounts').valueChanges();
    
  }
  

  getuserByName(username)
  {
   return  this.db.list('/accounts', ref => ref.orderByChild('name').equalTo(username)).valueChanges()
  }

  getUser(id: string) {
    return this.db.object('accounts/'+id).valueChanges().take(1);
  }
  
  // postUser(data): Observable<any> {
  //   return new Observable((observer) => {
  //     this.ref.add(data).then((doc) => {
  //       observer.next({
  //         key: doc.id,
  //       });
  //     });
  //   });
  // }
  
  updateUser(id: string , data){
    return this.db.object('accounts/'+id).update(data)
  }
  
  // deleteUser(id: string): Observable<{}> {
  //   return new Observable((observer) => {
  //     this.ref.doc(id).delete().then(() => {
  //       observer.next();
  //     });
  //   });
  // }
  
}
