import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Config } from 'configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class TokenService {

  url = "https://fcm.googleapis.com/fcm/send"
  serverkey = Config.messagingkey.serverkey

  constructor(public db: AngularFireDatabase,private http : HttpClient) { }

  getTokens()
  {
   return this.db.object('/accounts')
  }

  sendNotification(tokens , message, title )
  {
    debugger
    console.log(tokens)
        const httpOptions = {
          headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Key='+this.serverkey
      })}

    let body = {
      
        //"to" : JSON.stringify(tokens) ,
        "registration_ids" : tokens,
        "notification" : { 
          "title" : title , 
          "body": message, 
          "sound" : "default",
          "mutable_content": true,
          "content_available" : true
        },
        "apns":{
          "headers":{"apns-priority" : "5"}
        }
        
      }
          
    return  this.http.post(this.url, body, httpOptions );

  }

}
