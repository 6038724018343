<!-- Filter Datatable Options Starts -->
<section id="filter" class="mb-3">
  <div class="row">
      <div class="col-12">
                    <div class="position-relative has-icon-right">
                        <input type="text" class="form-control round" placeholder="Search" (keyup)='updateFilter($event)'>
                    </div>
      </div>
  </div>
  <div class="row">
      <div class="col-sm-12">
            
         
          <ngx-datatable #table class='bootstrap' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
              [rowHeight]="'auto'"  [limit]="18" [rows]='rows'   >
              <ngx-datatable-column name="email"></ngx-datatable-column>
              <ngx-datatable-column name="name"></ngx-datatable-column>
              <ngx-datatable-column name="username"></ngx-datatable-column>
              <!-- <ngx-datatable-column name="Edit" sortable="false" prop="id">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template >
                  <button md-icon-button (click)='onUserEvent(row)'>
                    <i class="fa fa-pencil-square-o"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column > -->
              <ngx-datatable-column name="Ver Balance" sortable="false" prop="id" >
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template >
                  <button md-icon-button (click)="blockAgents(row)">
                    <h5><i class="fa fa-eur"></i></h5>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
      </div>
  </div>
</section>

