import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { BalancedeUsarioRoutingModule } from './balancedeusario-rauting.module';
import { MatchHeightModule } from 'app/shared/directives/match-height.directive';
import { BalancedeusarioComponent } from './balancedeusario.component';



@NgModule({
    imports: [
        BalancedeUsarioRoutingModule,
        MatchHeightModule,
        CommonModule,
        NgxDatatableModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [BalancedeusarioComponent],
    providers: [],
})
export class BalancedeUsarioModule { }