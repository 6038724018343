import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-addorderdetails',
  templateUrl: './addorderdetails.component.html',
  styleUrls: ['./addorderdetails.component.scss']
})
export class AddorderdetailsComponent implements OnInit {

  orderForm: FormGroup;
  constructor(private fb: FormBuilder) { }
  EventE
  ngOnInit() {
    this.orderForm = this.fb.group({
      productName:['',Validators.required],
      quantity:['', Validators.required],
      price: ['', Validators.required],
      color:['' , Validators.required]
    });
  }
 
 
}
